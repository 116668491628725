import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DateInput from "../DateInput";
import Text from "antd/es/typography/Text";
import TimePicker from "../../../../components/TimePicker/TimePicker";
import TimeQuantityPicker from "../../../../components/TimeQuantityPicker/TimeQuantityPicker";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import {
  PickUpAndDropOffWrapper,
  StyledColumn,
  StyledRow,
  StyledText,
} from "./PeriodCalendars.styles";
import { ShopIcon } from "../../../../../CheckInPage/CheckinScreen.styles";
import SelectBranch from "../../../../../CheckInPage/components/SelectBranch/SelectBranch";
import { parseHour } from "../../../../../../helpers/dates";

const HourCalendarInput = ({
  from,
  align,
  handleOnTimeChange,
  fromHours,
  onClick,
  disabledPickUpBranch,
  disabledDropOffBranch,
  pickUpBranch,
  dropOffBranch,
  supplierGroupsOptions,
  handleSetPickUpAndDropOffBranch,
  pickUpTime,
  quantityTime,
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("shop");

  const handleTimeQuantityChange = (value) => {
    handleOnTimeChange("quantityTime", parseInt(value));
  };

  const handleFromTimeChange = (key, value) => {
    handleOnTimeChange("pickupTime", value);
  };

  useEffect(() => {
    if (quantityTime && pickUpTime) {
      handleOnTimeChange(
        "dropoffTime",
        `${Number(pickUpTime?.slice(0, 2)) + Number(quantityTime)}:00`
      );
    }
  }, [quantityTime, pickUpTime]);

  return (
    <PickUpAndDropOffWrapper $isMobile={isMobile}>
      <Text>{t("serviceDetail")}</Text>
      <StyledRow columns={isMobile ? 1 : 2}>
        <StyledColumn>
          <StyledText>{t("pickUp")}</StyledText>
          <SelectBranch
            options={supplierGroupsOptions}
            value={pickUpBranch}
            disabled={disabledPickUpBranch}
            handleSelectBranch={(value, branchData) =>
              handleSetPickUpAndDropOffBranch(value, "pickUpBranch", branchData)
            }
            icon={<ShopIcon />}
          />
        </StyledColumn>

        <StyledColumn>
          <Text style={{ fontSize: 16 }}>{t("dropOff")}</Text>
          <SelectBranch
            style={{ marginBottom: isMobile && "15px" }}
            options={supplierGroupsOptions}
            disabled={disabledDropOffBranch}
            value={dropOffBranch}
            handleSelectBranch={(value, branchData) =>
              handleSetPickUpAndDropOffBranch(
                value,
                "dropOffBranch",
                branchData
              )
            }
            icon={<ShopIcon />}
          />
        </StyledColumn>
      </StyledRow>

      <StyledRow columns={isMobile ? 1 : 3}>
        <DateInput
          label={t("startDate")}
          value={from}
          align={align}
          onClick={onClick}
        />

        <TimeQuantityPicker
          onChange={handleTimeQuantityChange}
          value={quantityTime}
          values={[
            {
              label: "1 hora",
              value: "1",
            },
            {
              label: "2 horas",
              value: "2",
            },
            {
              label: "4 horas",
              value: "4",
            },
            {
              label: "Todo el día",
              value: "8",
            },
          ]}
          label={t("quantityByPeriods.hour_quantity")}
        />

        <TimePicker
          label={t("startDate")}
          onChange={handleFromTimeChange}
          keyVal="pickupTime"
          fromTime={fromHours?.map((hour) => hour.from.split(":")[0]).flat()}
          toTime={fromHours?.map((hour) => hour.to.split(":")[0]).flat()}
          disabled={quantityTime === null}
          dateSelected={from}
          amountSelected={quantityTime}
          timeSelected={parseHour(pickUpTime)}
        />
      </StyledRow>
    </PickUpAndDropOffWrapper>
  );
};

export default HourCalendarInput;
