import React from "react";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import Text from "antd/es/typography/Text";
import SelectBranch from "../../../../../CheckInPage/components/SelectBranch/SelectBranch";
import { ShopIcon } from "../../../../../CheckInPage/CheckinScreen.styles";
import DateAndTimePicker from "../../../../components/DateAndTimePicker/DateAndTimePicker";
import {
  PickUpAndDropOffWrapper,
  StyledColumn,
  StyledRow,
  StyledText,
} from "./PeriodCalendars.styles";
import FreeChargeDisclaimer from "../../../../components/FreeChargeDisclaimer/FreeChargeDisclaimer";
import { isEmpty } from "lodash";

const DaysCalendarInput = ({
  supplierSettings,
  fromHours,
  toHours,
  handleOnTimeChange,
  from,
  to,
  onClick,
  supplierGroupsOptions,
  disabledDropOffBranch,
  disabledPickUpBranch,
  handleSetPickUpAndDropOffBranch,
  pickUpBranch,
  dropOffBranch,
  pickUpTime,
  dropOffTime,
}) => {
  const { t } = useTranslation("shop");
  const isMobile = useIsMobile();

  return (
    <PickUpAndDropOffWrapper $isMobile={isMobile}>
      <Text>{t("serviceDetail")}</Text>

      <StyledRow columns={isMobile ? 1 : 2}>
        <StyledColumn>
          <StyledText>{t("pickUp")}</StyledText>
          <SelectBranch
            style={{ marginBottom: 10 }}
            icon={<ShopIcon />}
            options={supplierGroupsOptions}
            value={pickUpBranch}
            disabled={disabledPickUpBranch}
            handleSelectBranch={(value, branchData) =>
              handleSetPickUpAndDropOffBranch(value, "pickUpBranch", branchData)
            }
          />
          <DateAndTimePicker
            onClickDate={onClick}
            handleOnTimeChange={handleOnTimeChange}
            keyVal="pickupTime"
            fromTime={fromHours?.map((hour) => hour.from.split(":")[0]).flat()}
            toTime={fromHours?.map((hour) => hour.to.split(":")[0]).flat()}
            dateSelected={from}
            timeSelected={pickUpTime}
          />

          <FreeChargeDisclaimer
            disclaimerLabel={t("shop:freeChargeTime")}
            fromHour={supplierSettings.pickupTime[0]}
            toHour={supplierSettings.pickupTime[1]}
            hideText={isEmpty(supplierSettings?.pickupTime)}
            days={"Lunes a Viernes"}
            restDays={"Sábados: 10:00hs - 14:00hs"}
          />
        </StyledColumn>

        <StyledColumn>
          <StyledText>{t("dropOff")}</StyledText>
          <SelectBranch
            style={{ marginBottom: 10 }}
            icon={<ShopIcon />}
            options={supplierGroupsOptions}
            value={dropOffBranch}
            disabled={disabledDropOffBranch}
            handleSelectBranch={(value, branchData) =>
              handleSetPickUpAndDropOffBranch(
                value,
                "dropOffBranch",
                branchData
              )
            }
          />
          <DateAndTimePicker
            onClickDate={onClick}
            handleOnTimeChange={handleOnTimeChange}
            keyVal="dropoffTime"
            fromTime={toHours?.map((hour) => hour.from.split(":")[0]).flat()}
            toTime={toHours?.map((hour) => hour.to.split(":")[0]).flat()}
            dateSelected={to}
            timeSelected={dropOffTime}
          />

          <FreeChargeDisclaimer
            disclaimerLabel={t("shop:freeChargeTime")}
            fromHour={supplierSettings.dropoffTime[0]}
            toHour={supplierSettings.dropoffTime[1]}
            hideText={isEmpty(supplierSettings?.dropoffTime)}
            days={"Lunes a Sábados"}
          />
        </StyledColumn>
      </StyledRow>
    </PickUpAndDropOffWrapper>
  );
};

export default DaysCalendarInput;
