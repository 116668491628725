import React, { useEffect } from "react";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetPickUpAndDropOfTime } from "../../../../Redux/Actions/searchActions";
import {
  getAppConfigState,
  getSupplierData,
} from "../../../../Redux/Selectors/appConfigSelector";
import { RENTALS, isRental } from "../../../../helpers/rentals";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import baseTheme from "../../../../config/baseTheme";

const TimePicker = ({
  onChange,
  keyVal,
  fromTime,
  toTime,
  disabled = false,
  dateSelected,
  amountSelected,
  timeSelected,
}) => {
  const { t } = useTranslation("shop");
  const {
    data: { theme },
  } = useSelector(getAppConfigState);
  const dispatch = useDispatch();
  const supplierData = useSelector(getSupplierData);
  useEffect(() => {
    if (fromTime && toTime) {
      const from = Number(fromTime[0]);
      const to = Number(toTime[0]);
      const timeFrame = Array.from(
        { length: to - from + 1 },
        (_, index) => index + from
      );
      !timeFrame.includes(timeSelected) && resetTime();
    }
  }, [toTime]);

  const formatHour = (time) => {
    return moment().set("hour", time).format("HH:00");
  };
  const resetTime = () => {
    dispatch(resetPickUpAndDropOfTime(keyVal));
  };
  if (!fromTime || !toTime) return null;
  const arrayRange = (start, stop, step) => {
    if (
      moment().format("DD-MM-YY") === moment(dateSelected).format("DD-MM-YY")
    ) {
      start =
        Number(moment().format("HH")) > Number(start)
          ? moment().format("HH")
          : start;
    }
    const parsedStart = Number(start);
    const parsedStop = Number(amountSelected ? stop - amountSelected : stop);
    return Array.from(
      { length: (parsedStop - parsedStart) / step + 1 },
      (value, index) => parsedStart + index * step
    );
  };
  const timeOptions = fromTime
    .map((from, index) =>
      arrayRange(from, toTime[index], 1).map((time) => ({
        label: formatHour(time),
        value: time,
      }))
    )
    .flat();

  const timeLabarracaOptions = () => {
    return [
      {
        label: t("morning"),
        value: 10,
      },
      {
        label: t("afternoon"),
        value: 17,
      },
    ];
  };

  const handleOnChange = (val) => {
    const parsedValue = formatHour(val);
    onChange(keyVal, parsedValue);
  };

  return (
    <Dropdown
      style={{ border: `1px solid ${baseTheme.borders.gray}`, width: "100%" }}
      optionsGap={"0px"}
      icon={
        <BootstrapIcon
          iconName="clock"
          style={{ fontSize: 16, color: theme.colors.primary }}
        />
      }
      placeholder={t("time")}
      options={
        isRental(supplierData?._id, RENTALS.labarraca)
          ? timeLabarracaOptions()
          : timeOptions
      }
      onClick={(e) => e.stopPropagation()}
      onChange={handleOnChange}
      value={timeSelected}
      disabled={disabled}
      notFoundContent={t("noAvailableSchedules")}
    />
  );
};

export default TimePicker;
